import './App.scss';
import React from 'react';
import axios from 'axios';
import {
    Routes,
    Route,
} from "react-router-dom";
import Navigation from './Navigation'
import Darkroom from './Darkroom';
import Footer from './Footer'


const apiServer = 'https://chujungeng.com/cameraroll/';

class App extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <div className="App">
              <Navigation tags={this.props.tags} activeKey={this.props.activeTag.name} />
              <Darkroom tag={this.props.activeTag} apiServer={apiServer} />
              <Footer />
            </div>
        ); 
    }
}

class AppRoutes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: []
        };
    }

    componentDidMount() {
        // fetch tags from backend API
        axios.get(`${apiServer}tags`)
        .then(res => {
            const tags = res.data
            this.setState({tags})
        })
    }

    render() {
        if (this.state.tags === null || this.state.tags.length === 0) {
            return (
                <div className="App">
                    <Navigation tags={[]} activeKey={""} />
                    <div className="darkroom text-light"></div>
                    <Footer />
                </div>
            );
        }
        
        return (
            <Routes>
                <Route path="/" element={<App tags={this.state.tags} activeTag={this.state.tags[0]} />} />
                {this.state.tags.map(tag => <Route key={tag.id} path={"/" + tag.name} element={<App tags={this.state.tags} activeTag={tag} />} />)}
            </Routes>
        );
    }
}

export default AppRoutes;
