import './Darkroom.css'
import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import PhotoAlbum from 'react-photo-album';
import InfiniteScroll from 'react-infinite-scroll-component';
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";


class ImageView extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.addEventListener('contextmenu', (e) => {
          e.preventDefault();
        });
    };

    render() {
        if (this.props.images === null || this.props.images.length === 0 || this.props.index < 0) {
            return null;
        }

        return (
            <Lightbox
                open={this.props.index >= 0}
                close={this.props.handleClose}
                index={this.props.index}
                slides={this.props.images.map((img) => ({
                    src: img.path
                }))}
                plugins={[Zoom, Fullscreen, Thumbnails]}
                carousel={{ finite: true, preload: 1 }}
                thumbnails={{ width: 60, height: 40, padding: 2}}
            />
        );
    } 
}

class AlbumView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: []
        };
    }

    componentDidMount() {
        document.addEventListener('contextmenu', (e) => {
          e.preventDefault();
        });

        if (this.props.albums !== null && this.props.index >= 0 && this.props.index < this.props.albums.length) {
            const album = this.props.albums[this.props.index];
            axios.get(`${this.props.apiServer}albums/${album.id}/images`)
            .then(res => {
                const images = res.data
                this.setState({images});
            });
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.index !== this.props.index 
            && this.props.albums !== null 
            && this.props.index >= 0 
            && this.props.index < this.props.albums.length) {
                const album = this.props.albums[this.props.index];

                // clear previous state
                this.setState({images: []});

                // fetch images from backend API
                axios.get(`${this.props.apiServer}albums/${album.id}/images`)
                .then(res => {
                    const images = res.data
                    this.setState({
                        images: images,
                    });
                });
        }
    }

    render() {
        if (this.props.albums === null 
            || this.props.index < 0 
            || this.state.images === null 
            || this.state.images.length === 0) {
            return null;
        }

        return (
            <Lightbox
                open={true}
                close={this.props.handleClose}
                slides={this.state.images.map((img) => ({
                    src: img.path
                }))}
                plugins={[Zoom, Fullscreen, Thumbnails]}
                carousel={{ finite: true }}
                thumbnails={{ width: 60, height: 40, padding: 2}}
            />
        );
    } 
}

class Darkroom extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            images: [],
            albumView: false,
            currentPage: 1,
            hasMore: true,
            activeImageIndex: -1
        }

        this.showImage = this.showImage.bind(this);
        this.hideImage = this.hideImage.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
    }

    componentDidMount() {
        // fetch images from backend API
        if (this.props.tag != null) {
            axios.get(`${this.props.apiServer}tags/${this.props.tag.id}/${this.state.albumView? "albums": "images"}`)
            .then(res => {
                const images = res.data
                this.setState({images});
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.tag, this.props.tag) && this.props.tag != null) {
            // fetch images from backend API
            axios.get(`${this.props.apiServer}tags/${this.props.tag.id}/${this.state.albumView? "albums": "images"}`)
            .then(res => {
                const images = res.data
                this.setState({
                    images: images,
                    currentPage: 1,
                    hasMore: true,
                    activeImageIndex: -1
                });
            })
        }
    }

    fetchMoreData() {
        if (this.props.tag == null) {
            return;
        }

        const nextPage = this.state.currentPage + 1;
        axios.get(`${this.props.apiServer}tags/${this.props.tag.id}/${this.state.albumView? "albums": "images"}?page=${nextPage}`)
        .then(res => {
            const data = res.data
            if (data.length === 0) {
                this.setState({hasMore: false});
            } else {
                this.setState({
                    currentPage: nextPage,
                    hasMore: true,
                    images: this.state.images.concat(data)
                })
            }
        })
    }

    showImage(index) {
        if (index >= 0 && index < this.state.images.length) {
            this.setState(
                {
                    activeImageIndex: index
                }
            );
        }
    }

    hideImage() {
        this.setState(
            {
                activeImageIndex: -1
            }
        );
    }

    render () {
        const photos = this.state.albumView? 
            this.state.images.map((alb) => ({
                src: alb.cover.thumbnail,
                width: alb.cover.width_thumb,
                height: alb.cover.height_thumb,
                title: alb.title,
                desc: alb.description,
                src_og: alb.cover.path,
                width_og: alb.cover.width,
                height_og: alb.cover.height
            })) 
        : this.state.images.map((img) => ({
            src: img.thumbnail,
            width: img.width_thumb,
            height: img.height_thumb,
            title: img.title,
            desc: img.description,
            src_og: img.path,
            width_og: img.width,
            height_og: img.height
        }));

        const view = this.state.albumView ? 
            <AlbumView apiServer={this.props.apiServer} albums={this.state.images} index={this.state.activeImageIndex} handleClose={this.hideImage} /> :
            <ImageView images={this.state.images} index={this.state.activeImageIndex} handleClose={this.hideImage} />;

        return (
            <div className="darkroom text-light">
                <InfiniteScroll
                    dataLength={this.state.images.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                >
                    <PhotoAlbum 
                        layout="masonry" 
                        spacing={(containerWidth) => {
                            if (containerWidth >= 1200) return 10;
                            if (containerWidth >= 600) return 6;
                            if (containerWidth >= 300) return 4;
                            return 2;
                        }}
                        columns={(containerWidth) => {
                            if (containerWidth >= 1200) return 4;
                            if (containerWidth >= 600) return 3;
                            if (containerWidth >= 300) return 2;
                            return 1;
                        }}
                        targetRowHeight={(containerWidth) => {
                            if (containerWidth >= 1200) return containerWidth / 4;
                            if (containerWidth >= 600) return containerWidth / 3;
                            if (containerWidth >= 300) return containerWidth / 2;
                            return containerWidth;
                        }}
                        photos={photos} 
                        onClick={(event, photo, index) => {
                            
                            this.showImage(index);
                        }} 
                    >
                    </PhotoAlbum>
                </InfiniteScroll>

                {view}
                
            </div>
        );
    }
}

export default Darkroom;