import React from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function CustomNav(props) {
    if (props.tags === null || props.tags.length === 0) {
        return (
            <Nav className="me-auto">
                <Nav.Item>
                    <Nav.Link href="/">
                        Loading...
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        );
    }

    return (
        <Nav className="me-auto" activeKey={props.activeKey}>
            {props.tags.map(tag => <Nav.Item key={tag.id}><Nav.Link as={Link} to={"/" + tag.name} eventKey={tag.name} style={{textTransform: "capitalize"}}>{tag.name}</Nav.Link></Nav.Item>)}
        </Nav>
    );
}

function Navigation(props) {
    return (
        <Navbar bg="dark" variant="dark" expand="sm" fixed="top">
            <Container className="flex-sm-column">
                <Navbar.Brand className="mr-0" as={Link} to="/"><h1 style={{fontFamily: "Playfair Display"}}>CHUJUN GENG</h1></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <CustomNav tags={props.tags} activeKey={props.activeKey} />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;