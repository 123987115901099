import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

function Footer() {
    return (
        <footer className="text-light p-4 mt-4">
            <Container fluid>
                    <Row className="align-items-center p-2">
                        <Col className="d-flex justify-content-center">
                            <a href="https://www.instagram.com/potstickercraver/" target="_blank" rel="noreferrer" className="text-light p-4">
                                <FontAwesomeIcon icon={faInstagram} size="3x" />
                            </a>
                            <a href="https://github.com/chujungeng/camera-roll" target="_blank" rel="noreferrer" className="text-light p-4">
                                <FontAwesomeIcon icon={faGithub} size="3x" />
                            </a>
                            <a href="https://www.linkedin.com/in/chujungeng" target="_blank" rel="noreferrer" className="text-light p-4">
                                <FontAwesomeIcon icon={faLinkedin} size="3x" />
                            </a>
                        </Col>
                    </Row>
                    <Row className="align-items-center p-2">
                        <Col className="d-flex justify-content-center">
                            <p>Copyright &copy; {new Date().getFullYear()} Chujun Geng</p>
                        </Col>
                    </Row>
            </Container>
      </footer>
    );
  }
  
  export default Footer;